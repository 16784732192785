import React from 'react';
import { EventSummary as EventSummary1 } from './1/event-summary';
import { useStore, Headline2 } from '@matix/gatsby-theme-core';
export const EventRenderer = (props) => {
   const { store } = useStore();
   const now = new Date();
   const nextMonthDate = new Date();
   nextMonthDate.setDate(now.getDate() + 30);
   const lastNextDate = new Date();
   lastNextDate.setDate(now.getDate() + 90);
   const titleNext = store.translations.find((x) => x.identifier === 'eventNext');

   // if no Date, then recurring event
   const nextEvents = props.events?.filter((x) => {
      if (x.frontmatter.date) {
         const xDate = new Date(x.frontmatter.date);
         if (xDate < nextMonthDate) return false;
      }
      return true;
   });

   const titleCurrent = store.translations.find((x) => x.identifier === 'eventCurrent');
   const currentMonthEvents = props.events?.filter((x) => {
      if (x.frontmatter.date) {
         const xDate = new Date(x.frontmatter.date);
         if (xDate > now && xDate < nextMonthDate) return true;
      }
      return false;
   });

   const titlePast = store.translations.find((x) => x.identifier === 'eventPast');
   const pastEvents = props.events
      ?.filter((x) => {
         if (x.frontmatter.date) {
            const xDate = new Date(x.frontmatter.date);
            if (xDate < now) return true;
         }
         return false;
      })
      .reverse()
      .slice(0, 2);

   return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
         {props.style === '1' ? (
            <>
               <div className="my-2">
                  {titleCurrent && currentMonthEvents && currentMonthEvents.length ? (
                     <Headline2 title={`**${titleCurrent.value}**`} subTitle={null} />
                  ) : null}
                  <div className="flex flex-col md:flex-row flex-wrap justify-center" key={props.key}>
                     {currentMonthEvents?.map((a, idx) => (
                        <div className="md:w-1/2 xl:w-1/3 p-4">
                           <EventSummary1 key={idx} {...{ ...props, event: a.frontmatter }} />
                        </div>
                     ))}
                  </div>
               </div>
               <div className="my-2">
                  {titleNext && nextEvents && nextEvents.length ? <Headline2 title={`**${titleNext.value}**`} subTitle={null} /> : null}
                  <div className="flex flex-col md:flex-row flex-wrap justify-center" key={props.key}>
                     {nextEvents?.map((a, idx) => (
                        <div className="md:w-1/2 xl:w-1/3 p-4">
                           <EventSummary1 key={idx} {...{ ...props, event: a.frontmatter }} />
                        </div>
                     ))}
                  </div>
               </div>
               <div className="my-2">
                  {titlePast && pastEvents && pastEvents.length ? <Headline2 title={`**${titlePast.value}**`} subTitle={null} /> : null}
                  <div className="flex flex-col md:flex-row flex-wrap justify-center" key={props.key}>
                     {pastEvents?.map((a, idx) => (
                        <div className="md:w-1/2 xl:w-1/3 p-4">
                           <EventSummary1 key={idx} {...{ ...props, event: a.frontmatter }} />
                        </div>
                     ))}
                  </div>
               </div>
            </>
         ) : null}
      </>
   );
};
export default EventRenderer;
