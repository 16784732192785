import React from 'react';
import c from 'classnames';
import { EventFrontMatter, EventsProps } from '../index';
import { MdStringRenderer, Image, Link, WithImageControls } from '@matix/gatsby-theme-core';
import dateFormat from 'dateformat';

export interface EventSummaryProps extends EventsProps {
   event: EventFrontMatter;
}
export const EventSummary = (props: EventSummaryProps) => {
   const { image, title, summary, tags, pagePath, date, location, duration } = props.event;
   const { showPublishedDate, showMoreBtnText } = props;

   let summaryMax = summary;
   if (summaryMax.length > 377) {
      summaryMax = summaryMax.slice(0, 377);
      summaryMax = summaryMax.slice(0, summaryMax.lastIndexOf(' ')) + '...';
   }
   // const displayDate = dateFormat(new Date(date), 'dddd, mmmm dS, yyyy, h:MM:ss TT Z');
   let displayDate = null;
   if (date) {
      displayDate = dateFormat(new Date(date), 'yyyy-mm-dd  h:MM TT ( Z )');
   }
   return (
      <div className="group flex flex-col overflow-hidden items-center justify-center bg-primary-light text-primary-light shadow-md">
         <div className="flex relative h-96 w-full overflow-hidden">
            <WithImageControls
               className="w-full object-fit transition-all transform duration-500 ease-in-out group-hover:scale-105"
               {...{ ...props, image }}
            />
         </div>

         <div className={c('h-full flex flex-col px-5 space-y-3')}>
            {title ? (
               <div className="my-5 font-bold text-3xl">
                  <MdStringRenderer source={title} />
               </div>
            ) : null}
            {displayDate ? (
               <div className={c('flex flex-col fex-wrap p-5 w-full bg-secondary-light text-secondary-light font-thin')}>
                  <MdStringRenderer source={displayDate.toString()} />
                  <MdStringRenderer source={duration} />
                  <MdStringRenderer source={location} />
               </div>
            ) : null}

            {showPublishedDate ? (
               <div className="text-sm font-extralight my-1">
                  <MdStringRenderer source={'Publié le 16 aout 2020'} />
               </div>
            ) : null}
            {tags ? (
               <div className="flex flex-wrap text-sm font-extralight my-1">
                  {tags?.map((item: string) => (
                     <div key={item} className="flex p-2 m-1 bg-black text-white">
                        <MdStringRenderer className="text-black no-underline" source={item} />
                     </div>
                  ))}
               </div>
            ) : null}
            <div className="flex-grow text-sm md:text-base my-2 text-justify">
               <MdStringRenderer source={summaryMax} />
            </div>
            <div className="flex-none my-2 text-right underline">
               <div className="p-2 text-primary-light">
                  <MdStringRenderer source={`[${showMoreBtnText}](${pagePath})`} />
               </div>
            </div>
         </div>
      </div>
   );
};
